.button {
  appearance: none;
  background-color: var(--theme-base-shade-6);
  border: 1px solid var(--theme-base-shade-6);
  border-radius: var(--global-border-radius);
  color: var(--global-color-light);
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 1.15;
  outline: none;
  padding: 6px 12px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  transition: var(--global-transition);
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.button:disabled:hover {
  background-color: var(--theme-base-shade-6);
  border-color: var(--theme-base-shade-6);
}
.button:hover {
  background-color: var(--theme-base-shade-5);
  border-color: var(--theme-base-shade-5);
  color: var(--global-color-light);
}
.button--outline {
  background: transparent;
  border-color: var(--theme-base-shade-6);
  color: var(--global-color-light);
}

.button--transparent {
  background-color: transparent;
  border-color: transparent;
  color: var(--global-color-dark);
  text-shadow: none;
}
.button--transparent:hover {
  background-color: transparent;
  border-color: transparent;
  color: var(--global-color-light);
}
.button--transparent:disabled:hover {
  background-color: transparent;
  border-color: transparent;
}

.button--white {
  background-color: var(--theme-base-tint-1);
  border-color: var(--theme-base-tint-1);
  color: var(--global-color-dark);
  text-shadow: none;
}
.button--white:hover {
  background-color: var(--theme-base-tint-3);
  border-color: var(--theme-base-tint-3);
  color: var(--global-color-dark);
}
.button--white.button--outline {
  background-color: transparent;
  color: var(--theme-base-tint-1);
}
.button--white.button--outline:hover {
  background-color: var(--theme-base-tint-3);
  color: var(--global-color-dark);
}
.button--white:disabled:hover {
  background-color: var(--theme-base-tint-1);
  border-color: var(--theme-base-tint-1);
}

.button--red {
  background-color: var(--theme-red);
  border-color: var(--theme-red);
  color: var(--global-color-light);
}
.button--red:hover {
  background-color: var(--theme-red-hover);
  border-color: var(--theme-red-hover);
  color: var(--global-color-light);
}
.button--red.button--outline {
  background-color: transparent;
  color: var(--theme-red);
}
.button--red.button--outline:hover {
  background-color: var(--theme-red-hover);
  color: var(--global-color-light);
}
.button--red:disabled:hover {
  background-color: var(--theme-red);
  border-color: var(--theme-red);
}

.button--green {
  background-color: var(--theme-green);
  border-color: var(--theme-green);
  color: var(--global-color-light);
}
.button--green:hover {
  background-color: var(--theme-green-hover);
  border-color: var(--theme-green-hover);
  color: var(--global-color-light);
}
.button--green.button--outline {
  background-color: transparent;
  color: var(--theme-green);
}
.button--green.button--outline:hover {
  background-color: var(--theme-green-hover);
  color: var(--global-color-light);
}
.button--green:disabled:hover {
  background-color: var(--theme-green);
  border-color: var(--theme-green);
}

.button--large {
  padding: 10px 20px;
}

.button--timer {
  padding-left: 6px;
  padding-right: 6px;
}

.button--round {
  border-radius: 50px;
}

.button-group {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.button-group > .button + .button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 2px;
}
.button-group > .button:first-of-type {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
