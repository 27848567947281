.chat {
  flex-grow: 1;
  max-height: calc(100vh - 122px);
  min-height: 180px;
  overflow-y: scroll;
  user-select: text;
  width: 294px;
}

.chat__empty {
  color: var(--global-color-dark);
  line-height: 1.25;
  padding: 8px;
  text-align: center;
}

.chat__item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: 1.25;
  padding: 6px 12px 7px 12px;
  position: relative;
}
.chat__item:first-child {
  padding-top: 0;
}
.chat__item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.chat__item:after {
  background-color: var(--theme-base-shade-8);
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 12px;
  position: absolute;
  right: 12px;
}
.chat__item:last-child:after {
  display: none;
}
.chat__item--last-read:after {
  background-color: var(--theme-red);
}
.chat__item:last-child:before {
  display: none;
}
.chat__item--last-read:before {
  background-color: var(--sidebar-background);
  bottom: 1px;
  color: var(--theme-red);
  content: 'New';
  display: block;
  left: 50%;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%) translateY(50%);
  z-index: 1;
}

.chat__icon {
  padding-right: 8px;
  position: relative;
  top: 1px;
}

.chat__container {
  width: 240px;
}

.chat__message {
  cursor: text;
  flex-grow: 1;
  line-height: 1.4;
  white-space: pre-wrap;
  width: 200px;
  word-wrap: break-word;
}

.chat__message a {
  color: var(--global-color-light);
  transition: var(--global-transition);
}
.chat__message a:hover {
  color: var(--theme-base-tint-5);
}
.chat__message a:visited {
  color: var(--theme-base-tint-12);
}
.chat__message a:visited:hover {
  color: var(--theme-base-shade-1);
}

.chat__clue {
  color: var(--global-color-dark);
  cursor: pointer;
  float: right;
  font-family: var(--global-font-family-monospace);
  font-size: 80%;
  margin-left: 8px;
  position: relative;
  top: 3px;
  transition: var(--global-transition);
  user-select: none;
}
.chat__clue:hover {
  color: var(--theme-base-tint-12);
}

.chat__clue > * {
  margin-left: 4px;
}
.chat__clue > :first-child {
  margin-left: 0;
}
