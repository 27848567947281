p {
  line-height: 1.4;
  margin: 0 0 1em 0;
}

.monospace {
  font-family: var(--global-font-family-monospace);
}

.color-dark {
  color: var(--global-color-dark);
}

.color-light {
  color: var(--global-color-light);
}
