.modal {
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
.modal--center {
  align-items: center;
  overflow: hidden;
}

.modal__content {
  background: var(--sidebar-background);
  border-radius: var(--global-border-radius);
  color: var(--global-color-light);
  display: block;
  padding: 30px;
  position: relative;
  z-index: 1;
}
.modal--center .modal__content {
  max-height: calc(100vh - 30px * 2);
  overflow-y: auto;
}
