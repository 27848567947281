.dropdown {
  position: relative;
}

.dropdown__panel {
  background-color: var(--sidebar-background);
  border-radius: var(--global-border-radius);
  bottom: 100%;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.2);
  color: var(--global-color-light);
  margin-bottom: 12px;
  min-width: 200px;
  position: absolute;
  right: -5px;
  z-index: 20;
}
.dropdown__panel:before {
  background-color: var(--theme-base-shade-8);
  border-radius: var(--global-border-radius);
  content: '';
  display: block;
  height: 20px;
  margin-top: -15px;
  position: absolute;
  right: 13px;
  top: 100%;
  transform: rotate(45deg);
  width: 20px;
  z-index: -1;
}

.dropdown__body {
  padding: 10px 0;
}

.dropdown__link {
  background: none;
  border: none;
  color: var(--global-color-light);
  cursor: pointer;
  display: block;
  outline: none;
  padding: 8px 12px 8px 36px;
  position: relative;
  text-align: left;
  transition: var(--global-transition);
  width: 100%;
}
.dropdown__link:hover {
  background-color: var(--theme-base-shade-10);
}
.dropdown__link:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.dropdown__link:disabled:hover {
  background-color: transparent;
}

.dropdown__icon {
  left: 0;
  position: absolute;
  text-align: center;
  width: 36px;
}

.dropdown__divider {
  border: none;
  height: 1px;
  margin: 10px 0;
  position: relative;
  width: 100%;
}
.dropdown__divider::after {
  background-color: var(--theme-base-shade-8);
  content: '';
  height: 1px;
  left: 12px;
  position: absolute;
  right: 12px;
  top: 0;
}

.dropdown__footer {
  background-color: var(--theme-base-shade-8);
  border-bottom-left-radius: var(--global-border-radius);
  border-bottom-right-radius: var(--global-border-radius);
  box-shadow: 0px -2px 10px -2px rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 100%;
}

.dropdown__input {
  appearance: none;
  background-color: var(--theme-base-shade-6);
  border: none;
  border-radius: var(--global-border-radius);
  width: 100%;
  color: var(--global-color-light);
  padding: 6px 10px;
}
.dropdown__input:focus {
  outline: none;
}
.dropdown__input::selection {
  background: var(--theme-base);
}
.dropdown__input::placeholder {
  color: var(--theme-base-tint-13);
  opacity: 1;
}

.dropdown__button {
  display: block;
  width: 100%;
}
