.toolbar {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px 20px;
}

.toolbar__meta {
  color: var(--global-color-dark);
  font-size: 75%;
  letter-spacing: 0.08em;
  padding: 5px 0;
  text-transform: uppercase;
}

.toolbar__timer-icon {
  cursor: pointer;
}

.toolbar__timer-label {
  margin: 0 20px 0 4px;
}

.toolbar__timer-done {
  color: var(--theme-green);
}

.toolbar__actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.settings-toggle,
.chat-toggle {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--global-color-dark);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  outline: none;
  padding: 0;
  position: relative;
  transition: var(--global-transition);
  width: 36px;
}
.settings-toggle:hover,
.chat-toggle:hover {
  color: var(--global-color-light);
}
.settings-toggle--open,
.chat-toggle--open {
  color: var(--global-color-light);
}
.settings-toggle--open:hover,
.chat-toggle--open:hover {
  color: var(--global-color-light);
}

.settings-toggle__icon,
.chat-toggle__icon {
  line-height: 1;
}

.chat-toggle__unread {
  background-color: var(--theme-red);
  box-shadow: 0 0 0 1px var(--theme-base);
  border-radius: 50%;
  height: 8px;
  left: 50%;
  margin-left: 2px;
  margin-top: 1px;
  overflow: hidden;
  position: absolute;
  text-indent: -9999px;
  top: 50%;
  width: 8px;
}
