.checkmark {
  background-color: var(--theme-green);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 10px auto;
}

.checkmark__circle {
  fill: none;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke: var(--global-color-light);
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
