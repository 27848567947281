:root {
  --theme-base: #1c2128;
  --theme-red: #de7373;
  --theme-green: #64b448;
  --theme-blue: #5199d9;

  --theme-red-hover: #ba6161;
  --theme-green-hover: #54973c;

  --theme-base-shade-13: #040405;
  --theme-base-shade-12: #0c0e11;
  --theme-base-shade-11: #14171c;
  --theme-base-shade-10: var(--theme-base);
  --theme-base-shade-9: #242b34;
  --theme-base-shade-8: #2c343f;
  --theme-base-shade-7: #343e4b;
  --theme-base-shade-6: #3c4756;
  --theme-base-shade-5: #445162;
  --theme-base-shade-4: #4c5a6d;
  --theme-base-shade-3: #556479;
  --theme-base-shade-2: #5d6d84;
  --theme-base-shade-1: #657790;
  --theme-base-tint-13: #6e809a;
  --theme-base-tint-12: #7a8ba2;
  --theme-base-tint-11: #8595aa;
  --theme-base-tint-10: #919fb2;
  --theme-base-tint-9: #9da9ba;
  --theme-base-tint-8: #a8b3c2;
  --theme-base-tint-7: #b4bdca;
  --theme-base-tint-6: #bfc7d2;
  --theme-base-tint-5: #cbd1da;
  --theme-base-tint-4: #d6dbe2;
  --theme-base-tint-3: #e2e5eb;
  --theme-base-tint-2: #edf0f3;
  --theme-base-tint-1: #f9fafb;

  --global-font-family-sans-serif: 'Roboto', '-apple-system',
    'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial',
    'sans-serif';
  --global-font-family-monospace: 'Roboto Mono', 'SFMono-Regular', 'Menlo',
    'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';

  --global-background: var(--theme-base);
  --global-border: var(--theme-base-shade-8);
  --global-border-radius: 4px;

  --global-color-light: var(--theme-base-tint-1);
  --global-color-dark: var(--theme-base-shade-2);

  --global-transition-speed: 0.15s;
  --global-transition-easing: ease-out;
  --global-transition: all 0.15s ease-out;
  --global-transition-slow: all 0.3s ease-out;

  --scrollbar: rgba(0, 0, 0, 0.3);
  --scrollbar-border: rgba(255, 255, 255, 0.15);
  --scrollbar-track: transparent;

  --sidebar-background: var(--theme-base-shade-9);
  --sidebar-border: var(--theme-base-shade-7);
  --sidebar-width: 280px;

  --clue-border: var(--theme-base-shade-9);
  --clue-selected-background: var(--theme-base-shade-2);
  --clue-selected-alt-background: var(--theme-base-shade-8);

  --puzzle-background: var(--theme-base);

  --grid-border: var(--theme-base-shade-11);
  --grid-background: var(--theme-base-shade-9);
  --grid-inactive: var(--theme-base-shade-11);
  --grid-selected: var(--theme-base-shade-4);
  --grid-selected-border: var(--theme-blue);
  --grid-highlighted: var(--theme-base-shade-7);

  --toolbar-background: var(--theme-base-shade-9);
  --toolbar-border: var(--theme-base-shade-8);
}
