.scoreboard {
  align-items: center;
  backface-visibility: hidden;
  background: var(--sidebar-background);
  border: 1px solid var(--sidebar-border);
  border-right: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: -28px;
  max-height: calc(100% - 140px);
  overflow-y: auto;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 100;
}

.scoreboard__left {
  border-bottom: 1px solid var(--theme-base-shade-8);
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.scoreboard__grid {
  margin-bottom: 8px;
  pointer-events: none;
}

.scoreboard__right {
}

.scoreboard__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.scoreboard__item {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--global-font-family-monospace);
  margin-bottom: 10px;
}
.scoreboard__item:last-child {
  margin-bottom: 0;
}

.scoreboard__item * + * {
  margin-left: 8px;
}

.scoreboard__icon {
}

.scoreboard__percentage {
}

.scoreboard__count {
  color: var(--global-color-dark);
  font-size: 0.8em;
}
