.puzzle {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  padding: 10px;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .puzzle {
    padding: 20px;
  }
}

.grid-wrapper {
  display: block;
  max-width: 740px;
  width: calc(100vh - 76px);
  position: relative;
}
@media screen and (min-width: 768px) {
  .grid-wrapper {
    width: calc(100vh - 96px);
  }
}
.grid-wrapper--tiny {
  width: 160px;
}

.grid-wrapper:before {
  content: '';
  display: block;
  width: 100%;
}
.grid-wrapper--15x15:before {
  padding-top: 100%;
}

.grid-wrapper > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.grid {
  display: grid;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  padding: 1px;
}
.grid--15x15 {
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.grid__cell {
  background-color: var(--grid-background);
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px var(--grid-border);
  color: var(--global-color-light);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  transition: background-color var(--global-transition-speed)
      var(--global-transition-easing),
    border-color var(--global-transition-speed) var(--global-transition-easing);
}
.grid__cell--inactive {
  background-color: var(--grid-inactive);
  cursor: default;
}

.grid__cell-number {
  fill: var(--global-color-light);
  font-family: var(--global-font-family-monospace);
}

.grid__cell-input {
  fill: var(--global-color-light);
  font-family: var(--global-font-family-sans-serif);
}

.grid__cell-error {
  fill: var(--theme-red);
}
