.sidebar {
  background: var(--sidebar-background);
  border-bottom: 1px solid var(--sidebar-border);
  display: flex;
  flex: 0 0 30vh;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media screen and (min-width: 768px) {
  .sidebar {
    border-bottom: none;
    border-right: 1px solid var(--sidebar-border);
    flex-basis: var(--sidebar-width);
    flex-direction: column;
  }
}
@media screen and (min-width: 1281px) {
  .sidebar {
    flex-direction: row;
    flex-basis: calc(var(--sidebar-width) * 2);
  }
}

.sidebar__section {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
}
.sidebar__section + .sidebar__section {
  border-left: 1px solid var(--sidebar-border);
}
@media screen and (min-width: 768px) {
  .sidebar__section + .sidebar__section {
    border-left: none;
    border-top: 1px solid var(--sidebar-border);
  }
}
@media screen and (min-width: 1281px) {
  .sidebar__section + .sidebar__section {
    border-left: 1px solid var(--sidebar-border);
    border-top: none;
  }
}

.sidebar__title {
  color: var(--global-color-dark);
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .sidebar__title {
    padding: 20px 20px 10px 20px;
    text-align: left;
  }
}

.clue-list {
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .clue-list {
    padding: 0 10px 10px 10px;
  }
}

.clue-list__item {
  border-radius: var(--global-border-radius);
  cursor: pointer;
  display: block;
  padding: 8px 10px 8px 40px;
  position: relative;
  transition: background-color var(--global-transition-speed)
      var(--global-transition-easing),
    box-shadow var(--global-transition-speed) var(--global-transition-easing);
}

.clue-list__item-label {
  font-family: var(--global-font-family-monospace);
  font-size: 12px;
  left: 0;
  line-height: 1;
  margin-top: -6px;
  position: absolute;
  text-align: right;
  top: 50%;
  width: 30px;
}
