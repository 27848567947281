::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar);
  border: 1px solid var(--scrollbar-border);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar);
}

::selection {
  background: var(--theme-base);
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--global-background);
  color: var(--global-color-light);
  cursor: default;
  font-family: var(--global-font-family-sans-serif);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  user-select: none;
}
.body--modal-open {
  overflow: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}
@media screen and (min-width: 768px) {
  .app {
    flex-direction: row;
    height: 100vh;
    min-height: auto;
  }
}

.center {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
}
